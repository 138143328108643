import { io } from 'socket.io-client';
import Vuex from 'vuex';

const socket = io('https://fundacionmasluz.com');

socket.on('connect');

export default new Vuex.Store({
  state: {
    socket,
    data: [],
  },
  mutations: {
    SOCKET_ON(state, { event, callback }) {
      state.socket.on(event, (data) => {
        if (callback && typeof callback === 'function') {
          callback(data);
        }
      });
    },
    SOCKET_EMIT(state, { event, room, data }) {
      state.socket.emit(event, room, data);
    },
  },
  actions: {
    socketOn({ commit }, { event, callback }) {
      commit('SOCKET_ON', { event, callback });
    },
    socketEmit({ commit }, { event, room, data }) {
      commit('SOCKET_EMIT', { event, room, data });
    },
  },
});
